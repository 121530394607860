@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@500;800&display=swap');

*{
    font-family: 'Dosis', sans-serif;
    margin: 0;
    padding: 0;
    
}

section[id]{
    scroll-margin-top: 78px;
}

.App{
    align-items: center;
    text-align: center;
    
    

    .Seccion1{
        color: #e5e5e5;
        background-color: #1d1b17;
    
    }

    .Seccion2{
        background-color: #e5e5e5;
        color: #1d1b17;
    }

    .Seccion3{
        background-color: #1d1b17;
        color: #e5e5e5;
    }

    .Seccion4{
        color: #1d1b17;
        background-color: #e5e5e5;
    }

    .Seccion5{
        background-color: #1d1b17;
        color: #e5e5e5;
    }

    .Seccion6{
        background-color: #e5e5e5;
        color: #1d1b17;
    }

    .Seccion7{
        background-color: #1d1b17;
        color: #e5e5e5;
    }

    .Seccion8{
        background-color: #e5e5e5;
        color: #1d1b17;
    }

    .Seccion9{
        background-color: #1d1b17;
        color: #e5e5e5;
    }

}