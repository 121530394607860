
.Sec1{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-template-areas: "Contenido1 Contenido2";

    .Contenido1{
        margin: 80px 2.5%;
        grid-area: Contenido1;
        text-align: justify;
        .nombre{
            font-size: 40px;
            text-align: center;
        }
        .desp{
            font-size: 20px;
            margin: 10px;
        }
    }

    .Contenido2{
        margin: 80px;
        grid-area: Contenido2;
    }
}

@media screen and (max-width: 768px){

    .Sec1{
        grid-template-areas: "Contenido1 Contenido1"
                        "Contenido2 Contenido2";

        .Contenido2{
            margin: 0.01px;
        }
    }
    
}