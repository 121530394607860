

.Sec4{
    display: grid;
    grid-template-columns: repeat(2, 1ft);
    grid-template-areas: "Cont1 Cont2";

    .Cont1{
        .tituloo{
            font-size: 40px;
        }
        grid-area: Cont1;
        
    }
    .Cont2{
        .tituloo{
            font-size: 40px;
        }
        grid-area: Cont2;
    }
}

@media screen and (max-width: 768px){
    .Sec4{
        grid-template-areas: "Cont1 Cont1"
                             "Cont2 Cont2";
    }
}