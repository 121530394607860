

.Sec3{
    text-align: center;
    align-items: center;
    display: grid;

    grid-template-areas:"titulo titulo titulo"
                        "Contenido1 Contenido2 Contenido3";

    .titulo{
        font-size: 40px;
        grid-area: titulo;
    }
    .Contenido1{
        grid-area: Contenido1;
    }
    .Contenido2{
        grid-area: Contenido2;
    }
    .Contenido3{
        grid-area: Contenido3;
    }
}

@media screen and (max-width: 768px){
    .Sec3{
        grid-template-areas: "titulo"
                             "Contenido1"
                             "Contenido2"
                             "Contenido3";
    }
}