

.Sec7{
    .contenido{

        display: grid;
        grid-template-areas: "titulo"
                             "text1"
                             "text2";

        .titulo{
            grid-area: titulo;
            font-size: 40px;
        }
        
        .text1{
            grid-area: text1;
            font-size: 30px;
            text-align: justify;  
            margin-left: 20%;  
            margin-right: 20%; 
               
        }
        .text2{
            grid-area: text2;
            font-size: 30px;
            text-align: left;
            margin-left: 20%;  
            margin-right: 20%;
        }
    }
}

@media screen and (max-width: 768px){
    .Sec7{
        .contenido{
    
            display: grid;
            grid-template-areas: "titulo"
                                 "text1"
                                 "text2";
    
            .titulo{
                grid-area: titulo;
                font-size: 40px;
            }
            
            .text1{
                grid-area: text1;
                font-size: 25px;
                text-align: left;  
                margin-left: 5%;  
                margin-right: 5%; 
                   
            }
            .text2{
                grid-area: text2;
                font-size: 25px;
                text-align: left;
                margin-left: 5%;  
                margin-right: 5%;
            }
        }
    }
}