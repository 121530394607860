
.Sec9{
    .conte{
        display: grid;
        text-align: center;
        grid-template-areas: "img tex ins";

        .img{
            text-align: left;
            margin-left: 10rem;
            grid-area: img;
        }

        .tex{
            text-align: center;
           grid-area: tex;
        }
      
        .ins{
            grid-area: ins;
            text-align: right;
            margin-right: 10rem;
            .btn-insta{
                border: 1.2rem;
                border-radius: 10%;
                padding: 1px 3px;
                cursor: pointer;
                background-image: linear-gradient(to right top, #ffdb6d, #ffbe60, #ffa05e, #ff8165, #ff6472, #ff4f7e, #ff378e, #f81ea2, #ee00b3, #de00c6, #c80ada, #a924ef);
                font-size: 20px;
                color: white;
                font-weight: bold;
            }
        }
        
    }
}

@media screen and (max-width: 768px){
    .Sec9{
        .conte{
            display: grid;
        text-align: center;
        grid-template-areas: "img tex ins";

        .img{
            text-align: left;
            margin-left: 3rem;
            grid-area: img;
        }

        .tex{
            text-align: center;
           grid-area: tex;
        }
      
        .ins{
            grid-area: ins;
            text-align: right;
            margin-right:3rem;
            .btn-insta{
                border: 1.2rem;
                border-radius: 10%;
                padding: 1px 3px;
                cursor: pointer;
                background-image: linear-gradient(to right top, #ffdb6d, #ffbe60, #ffa05e, #ff8165, #ff6472, #ff4f7e, #ff378e, #f81ea2, #ee00b3, #de00c6, #c80ada, #a924ef);
                font-size: 20px;
                color: white;
                font-weight: bold;
            }
        }
           
        }
    }
}