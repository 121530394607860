


.Sec8{
    .conte{

        display: grid;
        grid-template-areas: "titulo titulo titulo"
                             "face insta whats";

        .titulo{
            grid-area: titulo;
            font-size: 40px;
        }

        .face{
            grid-area: face;
            
            height: 6rem;
            width: 12rem;
            margin: auto;
            background-color: #e5e5e5;
            
            
            .btn-face{
                border: 1.2rem;
                align-content: center;
                align-items: center;
                border-radius: 10%;
                padding: 5px 10px;
                cursor: pointer;
                background-color: #3551bf;
                font-size: 20px;
                color: white;
                font-weight: bold;
            }
        }

        .insta{
            grid-area: insta;
            
            height: 6rem;
            width: 10rem;
            margin: auto;
            background-color: #e5e5e5;
            
            .btn-insta{
                border: 1.2rem;
                align-content: center;
                align-items: center;
                border-radius: 10%;
                padding: 5px 10px;
                cursor: pointer;
                background-image: linear-gradient(to right top, #ffdb6d, #ffbe60, #ffa05e, #ff8165, #ff6472, #ff4f7e, #ff378e, #f81ea2, #ee00b3, #de00c6, #c80ada, #a924ef);
                font-size: 20px;
                color: white;
                font-weight: bold;
            }
        }
        
        .whats{
            grid-area: whats;
            
            height: 6rem;
            width: 12rem;
            margin: auto;
            background-color: #e5e5e5;
            .btn-whats{
                border: 1.2rem;
                align-content: center;
                align-items: center;
                border-radius: 10%;
                padding: 5px 10px;
                cursor: pointer;
                background-color: #23c861;
                font-size: 20px;
                color: white;
                font-weight: bold;
            }
        }
        
    }
}

@media screen and (max-width: 768px){
    .Sec8{
        .conte{
            
            grid-template-areas: "titulo titulo"
                                 "face insta"
                                 "whats whats";
           
        }
    }
}