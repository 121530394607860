

.Sec2{
    
    .Contenido{

        .titulo{
            font-size: 40px;
        }

        .desc{
            font-size: 20px;
            margin: 2.5%;
            text-align: justify;
        }
        .list{
            font-size: 20px;
            align-items: center;
            text-align: left;
            margin-left: 40px;
            margin-right: 40px;
        }
        .descc{
            font-size: 20px;
            margin: 2.5%;
            text-align: justify;
           
        }
    }
}