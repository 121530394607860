

.Sec6{
    .conten{

        display: grid;
        grid-template-areas: "titulo titulo "
                             "Hemo Mata"
                             "Pro Sugar";

        .titulo{
            font-size: 40px;
            grid-area: titulo;
        }
        .Hemo{
            grid-area: Hemo;
            
            text-align: center;
            align-items: center;
          
            .btn-face{
                border: 1.2rem;
                align-content: center;
                align-items: center;
                border-radius: 20%;
                padding: 3px 6px;
                cursor: pointer;
                background-color: #3551bf;
                font-size: 20px;
                color: white;
                font-weight: bold;
                margin-right: 10px;
            }

            .btn-insta{
                margin-left: 10px;
                border: 1.2rem;
                align-content: center;
                align-items: center;
                border-radius: 20%;
                padding: 3px 6px;
                cursor: pointer;
                background-image: linear-gradient(to right top, #ffdb6d, #ffbe60, #ffa05e, #ff8165, #ff6472, #ff4f7e, #ff378e, #f81ea2, #ee00b3, #de00c6, #c80ada, #a924ef);
                font-size: 20px;
                color: white;
                font-weight: bold;
            }
 
        }
        .Mata{
            grid-area: Mata;
            .btn-face{
                border: 1.2rem;
                align-content: center;
                align-items: center;
                border-radius: 20%;
                padding: 3px 6px;
                cursor: pointer;
                background-color: #3551bf;
                font-size: 20px;
                color: white;
                font-weight: bold;
                margin-right: 10px;
            }
            .btn-insta{
                margin-left: 10px;
                border: 1.2rem;
                align-content: center;
                align-items: center;
                border-radius: 20%;
                padding: 3px 6px;
                cursor: pointer;
                background-image: linear-gradient(to right top, #ffdb6d, #ffbe60, #ffa05e, #ff8165, #ff6472, #ff4f7e, #ff378e, #f81ea2, #ee00b3, #de00c6, #c80ada, #a924ef);
                font-size: 20px;
                color: white;
                font-weight: bold;
            }
        }
        .Pro{
            grid-area: Pro;
            .btn-insta{
                margin-left: 10px;
                border: 1.2rem;
                align-content: center;
                align-items: center;
                border-radius: 20%;
                padding: 3px 6px;
                cursor: pointer;
                background-image: linear-gradient(to right top, #ffdb6d, #ffbe60, #ffa05e, #ff8165, #ff6472, #ff4f7e, #ff378e, #f81ea2, #ee00b3, #de00c6, #c80ada, #a924ef);
                font-size: 20px;
                color: white;
                font-weight: bold;
            }
        }
        .Sugar{
            grid-area: Sugar;
            .btn-face{
                border: 1.2rem;
                align-content: center;
                align-items: center;
                border-radius: 20%;
                padding: 3px 6px;
                cursor: pointer;
                background-color: #3551bf;
                font-size: 20px;
                color: white;
                font-weight: bold;
                margin-right: 10px;
            }
            .btn-insta{
                margin-left: 10px;
                border: 1.2rem;
                align-content: center;
                align-items: center;
                border-radius: 20%;
                padding: 3px 6px;
                cursor: pointer;
                background-image: linear-gradient(to right top, #ffdb6d, #ffbe60, #ffa05e, #ff8165, #ff6472, #ff4f7e, #ff378e, #f81ea2, #ee00b3, #de00c6, #c80ada, #a924ef);
                font-size: 20px;
                color: white;
                font-weight: bold;
            }
        }
    }
}

@media screen and (max-width: 768px){
    .Sec6{
        .conten{
            grid-template-areas: "titulo"
                                 "Hemo"
                                 "Mata"
                                 "Pro"
                                 "Sugar";

        }
    }
}