


.Sec5{

    .Conte{

        .titulo1{
            font-size: 40px;
        }
        .titulo2{
            font-size: 40px;
        }
        .titulo3{
            font-size: 40px;
        }

        display: grid;
        grid-template-areas: "titulo1 titulo2 titulo3"
                             "IMG1 IMG2 IMG3";


        .IMG1{
            grid-area: IMG1;
        }
        .IMG2{
            grid-area: IMG2;
        }
        .IMG3{
            grid-area: IMG3;
        }
        .titulo1{
            grid-area: titulo1;
        }
        .titulo2{
            grid-area: titulo2;
        }
        .titulo3{
            grid-area: titulo3;
        }
    }
}

@media screen and (max-width: 768px){
    .Sec5{
        .Conte{
            grid-template-areas: "titulo1"
                                 "IMG1"
                                 "titulo2"
                                 "IMG2"
                                 "titulo3"
                                 "IMG3";

        }
    }
}